import { ref, computed, reactive, watch, UnwrapRefSimple } from '@vue/composition-api'
import { IPaginatedService } from './types'
import debounce from 'lodash/debounce'
import { DataTable, Pagination } from './types'
import Vue from 'vue'

export default function <TItem>(
  service: IPaginatedService<TItem>,
  queryParams: Record<string, unknown> = Vue.observable({}),
  options?: { hasSearchButton?: boolean },
): DataTable<TItem> {
  const items = reactive<{ value: TItem[] }>({ value: [] })
  const pagination = reactive<{ value: Pagination }>({ value: {} })
  const totalRecords = ref(0)
  const loading = ref(true)
  const search = ref('')
  const query = ref(``)

  const pages = computed(() => {
    if (!pagination.value.itemsPerPage || !totalRecords.value) return 0

    return Math.ceil(totalRecords.value / pagination.value.itemsPerPage)
  })

  function prepareQuery(params: Record<string, unknown> = queryParams) {
    const { page, sortBy, sortDesc, itemsPerPage } = pagination.value

    let extraParams = ''
    for (const paramName in params) extraParams += `&${paramName}=${JSON.stringify(params[paramName])}`

    query.value = `&page=${page}`

    if (sortBy) query.value = query.value + `&sortBy=${sortBy}`
    if (sortDesc?.length) query.value = query.value + `&sortOrder=${sortDesc[0] ? 'DESC' : 'ASC'}`

    itemsPerPage === -1
      ? (query.value = `?noPagination=true` + query.value)
      : (query.value = `?pageSize=${itemsPerPage}` + query.value)

    if (search.value) extraParams += `&qs=${search.value}`

    return `${query.value}${extraParams}`
  }

  async function fetchData() {
    loading.value = true
    const q = prepareQuery()

    try {
      const { data } = await service.all(q)
      const apiItems = data.data.items
      const total = data.data.totalRecords

      if (data) {
        items.value = apiItems as UnwrapRefSimple<TItem>[]
        totalRecords.value = total
      }
    } catch (errors) {
      items.value = []
      totalRecords.value = 0
      // Todo: handle errors
      console.log(errors)
    } finally {
      loading.value = false
    }
  }

  watch(pagination, () => fetchData(), { deep: true })

  watch(
    queryParams,
    debounce(() => (!options ? fetchData() : !options.hasSearchButton ? fetchData() : null), 300),
    { deep: true },
  )

  watch(
    [search],
    debounce(() => {
      pagination.value.page = 1
      fetchData()
    }, 300),
    { deep: true },
  )

  return { items, totalRecords, loading, pagination, search, query, pages, fetchData }
}
